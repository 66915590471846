import '../styles.scss';

import { Button, Input } from 'antd';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type SubPlanFormProps = {
    title?: string;
    description?: string;
    descriptionList?: ReactNode;
    submitText?: string;
    onSubmit?: () => void;
    formSections: {
        title?: string;
        content: ReactNode;
    }[];
    className?: string;
    loading: boolean;
    onChangeReferralCode: (value: string) => void;
    showReferralCode?: boolean;
    hideButton?: boolean;
};

export const SubPlanForm: FC<SubPlanFormProps> = ({
    loading,
    title,
    description,
    descriptionList,
    submitText,
    onSubmit,
    formSections,
    className = '',
    showReferralCode = true,
    onChangeReferralCode,
    hideButton = false,
}: SubPlanFormProps) => {
    const { t } = useTranslation('common');

    return (
        <section className={`section-form space-y-6 w-full ${className}`}>
            {(title || description || descriptionList) && (
                <div className="text-center">
                    {title && <h3 className="text-lg mb-1">{title}</h3>}
                    {description && <p className="text-sm">{description}</p>}
                    {descriptionList}
                </div>
            )}

            {formSections.map((section, index) => (
                <section key={index}>
                    {section.title && <h3 className="sub-title mb-2 text-base">{section.title}</h3>}
                    {section.content}
                </section>
            ))}

            {showReferralCode && (
                <div>
                    <p className="text-sm font-bold mb-2">{t('plan_form.fields.reference_code')}</p>
                    <Input
                        onChange={(e) => onChangeReferralCode(e.target.value)}
                        placeholder={t('plan_form.fields.reference_code_placeholder')}
                    />
                </div>
            )}

            {hideButton && (
                <p className="italic text-red-600">{t('notifications.refresh_token')}</p>
            )}
            {submitText && !hideButton && (
                <>
                    <Button
                        type="primary"
                        className="w-full justify-center"
                        onClick={onSubmit}
                        loading={loading}
                    >
                        {submitText}
                    </Button>
                </>
            )}
        </section>
    );
};
