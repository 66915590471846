import { APPLICATIONS } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { IMarketplace } from '@interfaces/marketplace';
import { NRegistration } from '@interfaces/registration';
import { usePermissions } from '@refinedev/core';
import { getLocalTime } from '@utils/date';
import { validPermission } from '@utils/resource';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Progress } from 'antd';
import Tabs, { TabsProps } from 'antd/es/tabs';
import dayjs from 'dayjs';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    userPlan?: NRegistration.TUsedPlan;
    applications: IMarketplace.IApplication[];
    registration?: NRegistration.IRegistrationForDashboard;
    onClickUpgradeTable: () => void;
    onChangeOpenModal: (value: boolean, module?: IMarketplace.IApplication) => void;
};

enum TabEnum {
    PAYGATE_TAB = 'paygate',
    TAX_TAB = 'tax',
    PLAN_TAB = 'plan',
    CLOUD_TAB = 'cloud',
    FNET_MOBILE_TAB = 'fnet_mobile',
    COMBO_VIP_TAB = 'combo_vip',
    TICKET_TAB = 'ticket',
}
const LIMIT_PRINTER_QTY = 4;
const LIMIT_CONNECT_DEVICE_QTY = 4;

type AppAndActionType = {
    action: string;
    value: string;
    title: string;
    isExpired: boolean;
    application?: IMarketplace.IApplication;
};

export const DashboardReport: FC<Props> = ({
    userPlan,
    applications,
    registration,
    onChangeOpenModal,
    onClickUpgradeTable,
}) => {
    const items: TabsProps['items'] = [];
    const { t, i18n } = useTranslation('common');
    const { data: permissions } = usePermissions<string[]>();

    const isFreePlan = registration?.extraAppPlanId === POS_PLAN_ID.FREE;
    const isStandardPlan = [POS_PLAN_ID.STANDARD, POS_PLAN_ID.STANDARD_FNB].includes(
        registration?.extraAppPlanId,
    );

    const reportPlanContents = (
        plan: NRegistration.TUsedPlan,
        qtyTable: number,
        display: boolean,
    ) => [
        {
            label: t(`plan_form.printer_noti`, {
                usedPrinter: plan?.printerQuantity || 0,
                qtyPrinter: LIMIT_PRINTER_QTY,
            }),
            percent: Math.round(((plan?.printerQuantity || 0) * 100) / LIMIT_PRINTER_QTY),
            display,
        },
        {
            label: t(`plan_form.connect_device_noti`, {
                usedConnectDevice: plan?.connectDeviceQuantity || 0,
                qtyConnectDevice: LIMIT_CONNECT_DEVICE_QTY,
            }),
            percent: Math.round(
                ((plan?.connectDeviceQuantity || 0) * 100) / LIMIT_CONNECT_DEVICE_QTY,
            ),
            display,
        },
        {
            label: t(`plan_form.table_noti`, {
                usedTable: plan?.table || 0,
                qtyTable: qtyTable,
            }),
            percent: Math.round(((plan?.table || 0) * 100) / qtyTable),
            display: true,
        },
    ];

    const isExpiredDate = (expireDate: string): boolean => {
        if (!expireDate) return true;
        const currentDate = dayjs();
        return dayjs(getLocalTime(expireDate))
            .locale(`${i18n.language}`)
            .isBefore(currentDate, 'day');
    };

    const getAppAndAction = (
        applications: IMarketplace.IApplication[],
        permission: string,
    ): AppAndActionType => {
        let action = '',
            title = '',
            value = '',
            isExpired = false,
            application: IMarketplace.IApplication | undefined;

        switch (permission) {
            case PERMISSIONS.PAYGATE_LIST: {
                action = 'buy_more';
                title = 'remaining_paygate_transactions';
                value = t(`plan_form.turn`, { quantity: userPlan?.subscriptionQuantity || 0 });
                application = applications.find((i) => i.id === APPLICATIONS.PAYGATE.id);
                break;
            }

            case PERMISSIONS.TAX_LIST: {
                action = 'buy_more';
                title = 'remaining_tax_transactions';
                value = t(`plan_form.turn`, { quantity: userPlan?.taxQuantity || 0 });
                application = applications.find((i) => i.id === APPLICATIONS.TAX.id);
                break;
            }

            case PERMISSIONS.TICKET_LIST: {
                action = 'buy_more';
                title = 'remaining_ticket_transactions';
                value = t(`plan_form.turn`, { quantity: userPlan?.ticketQuantity || 0 });
                application = applications.find((i) => i.id === APPLICATIONS.TICKET.id);
                break;
            }

            case PERMISSIONS.FNET_MOBILE_LIST: {
                const applicationPlan = userPlan?.applicationExpiredTimes.find(
                    (i) => i.applicationId === APPLICATIONS.FNET_MOBILE.id,
                );

                action = 'extend_more';
                title = 'next_payment';
                application = applications.find((i) => i.id === APPLICATIONS.FNET_MOBILE.id);
                value = dayjs(getLocalTime(applicationPlan?.currentPeriodEnd))
                    .locale(`${i18n.language}`)
                    .format('ll');

                isExpired = applicationPlan?.currentPeriodEnd
                    ? isExpiredDate(applicationPlan.currentPeriodEnd)
                    : true;
                break;
            }

            case PERMISSIONS.CLOUD_LIST: {
                const applicationPlan = userPlan?.applicationExpiredTimes.find(
                    (i) => i.applicationId === APPLICATIONS.CLOUD.id,
                );

                action = 'extend_more';
                title = 'next_payment';
                application = applications.find((i) => i.id === APPLICATIONS.CLOUD.id);
                value = dayjs(getLocalTime(applicationPlan?.currentPeriodEnd))
                    .locale(`${i18n.language}`)
                    .format('ll');

                isExpired = applicationPlan?.currentPeriodEnd
                    ? isExpiredDate(applicationPlan.currentPeriodEnd)
                    : true;

                break;
            }

            case PERMISSIONS.COMBO_VIP_LIST: {
                const applicationPlan = userPlan?.applicationExpiredTimes.find(
                    (i) => i.applicationId === APPLICATIONS.COMBO_VIP.id,
                );

                action = 'extend_more';
                title = 'next_payment';
                application = applications.find((i) => i.id === APPLICATIONS.COMBO_VIP.id);
                value = dayjs(getLocalTime(applicationPlan?.currentPeriodEnd))
                    .locale(`${i18n.language}`)
                    .format('ll');

                isExpired = applicationPlan?.currentPeriodEnd
                    ? isExpiredDate(applicationPlan.currentPeriodEnd)
                    : true;
                break;
            }

            default: {
                value = '';
                break;
            }
        }
        return { action, application, title, value, isExpired };
    };

    const addTabItem = (key: string, label: string, children: ReactNode) => {
        items.push({ key, label, children });
    };

    const renderChildren = (
        title: string,
        action: string,
        value: string | number,
        application?: IMarketplace.IApplication,
    ) => {
        return (
            <div className="flex justify-between">
                <p className="text-sm font-semibold">{t(`plan_form.${title}`)}</p>
                <div className="flex flex-row justify-between">
                    <p>{value}</p>
                    <button
                        className="btn-add-more"
                        onClick={() => onChangeOpenModal(true, application)}
                    >
                        {t(`actions.${action}`)}
                    </button>
                </div>
            </div>
        );
    };

    const createPermissionTab = (data: { permission: string; key: string; label: string }) => {
        const { permission, key, label } = data;
        if (validPermission(permissions || [], permission)) {
            const { action, application, title, value, isExpired } = getAppAndAction(
                applications,
                permission,
            );
            // check if dynamic module is expired, not need to show
            if (isExpired) return;
            const children = renderChildren(title, action, value, application);
            addTabItem(key, t(label), children);
        }
    };

    if (registration) {
        addTabItem(
            TabEnum.PLAN_TAB,
            t(`plan_form.plan_type.${registration?.extraAppPlanName.toLowerCase()}`),
            <div className="plan-tab">
                <div>
                    {reportPlanContents(
                        registration?.usedPlan as NRegistration.TUsedPlan,
                        registration?.qtyTable || 0,
                        isStandardPlan,
                    ).map((i, index) => (
                        <div key={index} className={i.display ? 'pb-3' : 'hidden'}>
                            <p className="text-sm font-semibold">{i.label}</p>
                            <Progress className="h-5" percent={i.percent} />
                        </div>
                    ))}
                </div>

                {!registration?.isTrial && !isFreePlan && (
                    <Button
                        type="default"
                        className="button-action absolute bottom-0"
                        onClick={onClickUpgradeTable}
                    >
                        {t('plan_form.actions.upgrade_table')}
                    </Button>
                )}
            </div>,
        );
    }

    Object.values(TabEnum).forEach((tab) => {
        const permission = `${tab.toUpperCase()}_LIST` as keyof typeof PERMISSIONS;

        createPermissionTab({
            permission: PERMISSIONS[permission],
            key: tab,
            label: `modules_banner.${tab}.title`,
        });
    });

    return (
        <>
            {items.length ? (
                <div className="">
                    <div className={`report ${registration ? '' : 'rounded border p-5'}`}>
                        <Tabs defaultActiveKey="1" items={items} />
                    </div>
                </div>
            ) : null}
        </>
    );
};
