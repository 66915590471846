import { CheckIconOutline } from '@components/layout/icons';
import { CheckIconFilled } from '@components/layout/icons/check-filled.icon';
import { CurrencyType } from '@enums/currency-type.enum';
import { NApplication } from '@interfaces/application';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Checkbox, Form } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { getEnvConfig } from 'getEnvConfig';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { FieldTypeEnum } from '..';
import { PLAN_FEATURE_CONTENTS } from '../constant';

type Props = {
    isPopular: boolean;
    plan: NApplication.IExtraAppPlan;
    onRegister: (plan: NApplication.IExtraAppPlan) => void;
    rate?: number;
    isTrial?: boolean;
    discount?: number;
    isUsingForm?: boolean;
    trialLimitDays?: number;
    isSalesPCMonth?: boolean;
    addOnPlan?: NApplication.IExtraAppPlan;
    onChangeUsingTrial?: (e: CheckboxChangeEvent) => void;
};
export const PlanItem: React.FC<Props> = ({
    isPopular,
    plan,
    onRegister,
    rate = 1,
    isTrial,
    discount = 0,
    isUsingForm,
    trialLimitDays,
    isSalesPCMonth = true,
    addOnPlan,
    onChangeUsingTrial,
}) => {
    const { t } = useTranslation(['common']);

    const isPlanFree = plan.id === POS_PLAN_ID.FREE;
    const contactUsEmail = getEnvConfig.CONTACT_EMAIL;
    const planContents = PLAN_FEATURE_CONTENTS?.[plan.id] || [];
    const addOnPlanContents = addOnPlan ? PLAN_FEATURE_CONTENTS?.[addOnPlan.id] : [];

    return (
        <section
            className={twMerge(
                'p-6 flex flex-col rounded-lg border border-solid border-[#FF8057]',
                isPopular ? 'bg-[#FF8057]' : 'bg-white',
            )}
        >
            <div className="flex flex-col items-center gap-y-3">
                {/* plan name */}
                <h3
                    className={twMerge(
                        'font-normal text-[1.75rem]',
                        isPopular ? 'text-white' : 'text-[#FF8057]',
                    )}
                >
                    {t(`plan_form.plan_type.${plan.title.toLowerCase()}`)}
                </h3>

                {/* plan price */}
                <p>
                    {!plan.pricing ? (
                        <span className="text-[2rem] font-bold text-[#E1511F]">{t('free')}</span>
                    ) : (
                        <div className="flex gap-x-3 items-center">
                            <span
                                className={twMerge(
                                    'text-[2rem] font-bold leading-10',
                                    isPopular ? 'text-white' : 'text-[#E1511F]',
                                )}
                            >
                                {formatPriceBaseOnCurrency(
                                    ((plan.pricing || 0) * (100 - discount)) / 100,
                                    CurrencyType.Vnd,
                                )}
                            </span>
                            <span
                                className={twMerge(
                                    'text-xl leading-7',
                                    isPopular ? 'text-white' : 'text-[#E1511F]',
                                )}
                            >{`${
                                isSalesPCMonth ? t('plan_form.pc_month') : t('plan_form.month')
                            }`}</span>
                        </div>
                    )}
                </p>

                {/* plan feature contents */}
                <div className="w-full flex flex-col justify-start items-start gap-y-3 pt-3">
                    {planContents.map((content, index) => (
                        <div
                            key={`plan_feature_content_${index}`}
                            className="flex justify-start items-start gap-x-3"
                        >
                            <span className="mt-1">
                                {isPopular ? <CheckIconFilled /> : <CheckIconOutline />}
                            </span>
                            <span
                                className={twMerge(
                                    'text-base',
                                    isPopular
                                        ? 'text-white font-semibold'
                                        : 'text-black font-normal',
                                )}
                            >
                                {content}
                            </span>
                        </div>
                    ))}

                    {/* Add-on plan */}
                    {addOnPlan ? (
                        <div className="flex flex-col items-center bg-[#ff9979] rounded-xl p-4 gap-y-3 w-full">
                            <p className="text-white text-[1.75rem]">{t('add_on')}</p>
                            <p className="text-white text-base">
                                {t('add_on_plan_description', {
                                    fnbPrice: formatPriceBaseOnCurrency(
                                        addOnPlan.fnbPricing || 0,
                                        CurrencyType.Vnd,
                                    ),
                                })}
                            </p>

                            {addOnPlanContents.map((content, index) => (
                                <div
                                    key={`add_on_plan_feature_content_${index}`}
                                    className="flex justify-start items-start gap-x-3"
                                >
                                    <span className="mt-1">
                                        {isPopular ? <CheckIconFilled /> : <CheckIconOutline />}
                                    </span>
                                    <span
                                        className={twMerge(
                                            'text-base',
                                            isPopular
                                                ? 'text-white font-semibold'
                                                : 'text-black font-normal',
                                        )}
                                    >
                                        {content}
                                    </span>
                                </div>
                            ))}

                            <div className="flex gap-x-3 items-center">
                                <span
                                    className={twMerge(
                                        'text-[2rem] font-bold leading-10',
                                        isPopular ? 'text-white' : 'text-[#E1511F]',
                                    )}
                                >
                                    +
                                    {formatPriceBaseOnCurrency(
                                        Math.round((addOnPlan.fnbPricing || 0) / rate),
                                        CurrencyType.Vnd,
                                    )}
                                </span>
                                <span
                                    className={twMerge(
                                        'text-xl leading-7',
                                        isPopular ? 'text-white' : 'text-[#E1511F]',
                                    )}
                                >{`/${t('subscription_order.frequency_type.month')}`}</span>
                            </div>

                            <div className="w-full  cursor-pointer">
                                <Button
                                    className="w-full bg-white border-none"
                                    onClick={() => {
                                        onRegister(addOnPlan);
                                    }}
                                    disabled={false}
                                >
                                    <p className="text-center w-full font-bold text-[#E1511F]">
                                        {isTrial
                                            ? t(`plan_submit_btn.trial_add_on`)
                                            : t(`plan_submit_btn.add_on`)}
                                    </p>
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="mt-4">
                {isUsingForm &&
                [POS_PLAN_ID.STANDARD, POS_PLAN_ID.FNB_STANDARD].includes(plan.id) ? (
                    <Form.Item label={null} name={FieldTypeEnum.PLAN_TRIAL} valuePropName="checked">
                        <Checkbox onChange={onChangeUsingTrial}>
                            <span className="font-bold text-white">
                                {t('plan_form.fields.plan_trial', {
                                    trialLimitDays: trialLimitDays || 15,
                                })}
                            </span>
                        </Checkbox>
                    </Form.Item>
                ) : null}
            </div>

            <div className="w-full flex justify-center mt-6 cursor-pointer">
                <Button
                    type="primary"
                    disabled={false}
                    onClick={() => {
                        !isPlanFree && onRegister(plan);
                    }}
                    className={twMerge(
                        'w-full border-none',
                        isPopular ? 'bg-[#E1511F]' : 'bg-[#FF8057]',
                    )}
                >
                    <p className={twMerge('text-center w-full text-white')}>
                        {isPlanFree ? (
                            <a href={`mailto:${contactUsEmail}`}>
                                {t(`plan_submit_btn.contact_us`)}
                            </a>
                        ) : isTrial ? (
                            t(`plan_submit_btn.trial`)
                        ) : (
                            t(`plan_submit_btn.default`)
                        )}
                    </p>
                </Button>
            </div>
        </section>
    );
};
