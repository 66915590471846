import { Badge, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { IInvoice } from '../../../interfaces/invoice';
import { IQuote } from '../../../interfaces/quote';

interface BadgeItemsProps {
    items: IQuote.QuoteItem[] | IInvoice.InvoiceItem[];
}
export const BadgeItems = ({ items }: BadgeItemsProps) => {
    const { t } = useTranslation();
    const renderItemBadge = (item: IQuote.QuoteItem | IInvoice.InvoiceItem) => {
        const color =
            item.serviceId === null || item.itemType === ItemTypeEnum.MODULE ? 'green' : 'red';
        return (
            <Badge
                className="flex justify-start items-center"
                color={color}
                text={<span className="table-tbody-text">{item?.productName}</span>}
            />
        );
    };

    const NUMBER_ITEM_SHOW = 3;

    const renderItems = (items: IQuote.QuoteItem[] | IInvoice.InvoiceItem[]) => {
        const renderItemList = (itemList: IQuote.QuoteItem[] | IInvoice.InvoiceItem[]) => (
            <ul>{itemList?.map((item) => <li key={item.id}>{renderItemBadge(item)}</li>)}</ul>
        );
        return (
            <>
                {items?.map((item, index) => {
                    if (index < NUMBER_ITEM_SHOW) {
                        return <li key={item.id}>{renderItemBadge(item)}</li>;
                    }
                    if (index === NUMBER_ITEM_SHOW) {
                        return (
                            <Tooltip
                                title={() => renderItemList(items)}
                                overlayClassName="item-more"
                            >
                                <a className="table-tbody-text">
                                    {t('crm_internal.item_more', {
                                        ns: 'common',
                                        numberItem: items.length - NUMBER_ITEM_SHOW,
                                    })}
                                </a>
                            </Tooltip>
                        );
                    }
                    return null;
                })}
            </>
        );
    };
    return renderItems(items);
};
