import { IForm, OpenQrPaymentType, SubscriptionForm } from '@components/subscription-plan';
import { TIMEZONE_DEFAULT } from '@constants/index.constant';
import {
    SubscriptionOrderTypeActionEnum,
    SubscriptionOrderTypeEnum,
} from '@enums/subscription-order-type.enum';
import { NApplication } from '@interfaces/application';
import { NApplicationPrice } from '@interfaces/application-price';
import { IMarketplace } from '@interfaces/marketplace';
import { NSubscriptionOrder } from '@interfaces/subscription-order';
import { BaseKey, CreateResponse, HttpError, useCustom, useList, useOne } from '@refinedev/core';
import { notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import Response from '../../../data-access/responses/response';
import { NRegistration } from '@interfaces/registration';
import { dayjsDateDiff } from '@utils/date';
import dayjs from 'dayjs';

const APPLICATION_RESOURCE_URL = 'v1/application';

type HandlePaymentSubscriptionOrderType = {
    apiUrl: string;
    subscriptionOrder: NSubscriptionOrder.IInfo;
    mutate: any;
    onSuccess: (response: NApplication.IPlanResponse, type: SubscriptionOrderTypeEnum) => void;
    onError: (dto: { code: string }) => string;
};

type HandleModulesSubscriptionPaymentType = {
    apiUrl: string;
    mutate: any;
    values: SubscriptionForm;
    typeModal?: SubscriptionOrderTypeEnum;
    onSuccess: (data: OpenQrPaymentType) => void;
    onError: (dto: { code: string }) => string;
};

type HandleRegistrationActionPayment = {
    mutate: any;
    apiUrl: string;
    registrationId: string;
    action: SubscriptionOrderTypeActionEnum;
    values: IForm;
    onSuccess: (data: NApplication.IPlanResponse) => void;
    onError: (dto: { code: string }) => string;
};

export const useApplicationPrices = (
    applicationId: string,
): NApplicationPrice.IApplicationPrice[] => {
    const [prices, setPrices] = useState<NApplicationPrice.IApplicationPrice[]>([]);

    const { data: applicationPrices } = useOne<NApplicationPrice.IApplicationPrice[]>({
        resource: 'v1/application-price',
        id: applicationId as BaseKey,
        queryOptions: {
            enabled: !!applicationId,
        },
    });

    useEffect(() => {
        if (applicationPrices?.data?.length) {
            setPrices(applicationPrices?.data);
        }
    }, [applicationPrices?.data]);

    return prices || [];
};

export const useAvailableModules = (): IMarketplace.IApplication[] => {
    const [applications, setApplications] = useState<IMarketplace.IApplication[]>([]);

    const { data: listApplications } = useList<IMarketplace.IApplication>({
        resource: `${APPLICATION_RESOURCE_URL}/available-module`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'includeUnpublish',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });

    useEffect(() => {
        if (listApplications?.data) {
            setApplications(listApplications.data);
        }
    }, [listApplications]);

    return applications || [];
};

export const useHandlePaymentSubscriptionOrder = (dto: HandlePaymentSubscriptionOrderType) => {
    const { apiUrl, mutate, subscriptionOrder, onSuccess, onError } = dto;

    const url = `${apiUrl}/v1/subscription-orders/payment/${subscriptionOrder.id}`;

    mutate(
        {
            url: url,
            method: 'put',
            values: { timezone: TIMEZONE_DEFAULT },
            errorNotification: (error: HttpError) => {
                const code = error?.response?.data?.error;
                return {
                    message: onError({ code }),
                    type: 'error',
                };
            },
        },

        {
            onSuccess: (data: CreateResponse<Response<NApplication.IPlanResponse>>) => {
                const response = data.data?.data;

                if (data.data?.isSuccess) {
                    onSuccess(response, subscriptionOrder.type);
                } else {
                    notification.error({ message: onError({ code: 'upgrade_table_error' }) });
                }
            },
        },
    );
};

export const useHandleModulesSubscriptionPayment = (dto: HandleModulesSubscriptionPaymentType) => {
    const { apiUrl, mutate, values, typeModal, onSuccess, onError } = dto;
    const _values: SubscriptionForm & { timezone: string } = {
        ...values,
        timezone: TIMEZONE_DEFAULT,
    };

    mutate(
        {
            url: `${apiUrl}/v1/registration/subscription`,
            method: 'post',
            values: { ..._values },
            config: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
            },

            errorNotification: (error: HttpError) => {
                const code = error?.response?.data?.error;
                return {
                    message: onError({ code }),
                    type: 'error',
                };
            },
        },

        {
            onSuccess: (data: CreateResponse<Response<NApplication.IPlanResponse>>) => {
                const response = data.data?.data;
                onSuccess({
                    totalPayment: response.paymentAmount || 0,
                    url: response.qrUrl,
                    subscriptionOrderId: response.subscriptionOrderId as string,
                    title: `plan_form.success.${typeModal}_success`,
                });
            },
        },
    );
};

export const useHandleRegistrationActionPayment = (dto: HandleRegistrationActionPayment) => {
    const { apiUrl, mutate, values, action, registrationId, onSuccess, onError } = dto;
    const _values: IForm & { timezone: string } = { ...values, timezone: TIMEZONE_DEFAULT };

    mutate(
        {
            url: `${apiUrl}/v1/registration/${action}/${registrationId}`,
            method: 'put',
            values: { ..._values },
            config: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                },
            },

            errorNotification: (error: HttpError) => {
                const code = error?.response?.data?.error;
                return {
                    message: onError({ code }),
                    type: 'error',
                };
            },
        },
        {
            onSuccess: (data: CreateResponse<Response<NApplication.IPlanResponse>>) => {
                const response = data.data?.data;

                if (data.data?.isSuccess) {
                    onSuccess(response);
                } else {
                    notification.error({ message: onError({ code: 'upgrade_table_error' }) });
                }
            },
        },
    );
};

export const usePaymentFormula = ({
    expiredTime,
    price,
    qtyTable,
    fnbPrice = 0,
    isFnb,
}: {
    price: number;
    expiredTime: number;
    qtyTable: number;
    fnbPrice?: number;
    isFnb?: boolean;
}) => {
    return isFnb ? price * expiredTime : price * expiredTime * qtyTable + fnbPrice * expiredTime;
};

export const useRemainingMonths = (
    registration?: NRegistration.IRegistrationForDashboard,
): number => {
    const remainingMonths: number = useMemo(() => {
        if (!registration) return 0;

        return dayjsDateDiff(
            dayjs(registration.expiredDate),
            dayjs(),
            registration.isTrial ? registration.frequency : 'M',
        );
    }, [registration]);

    return remainingMonths;
};
