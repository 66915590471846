import './styles.scss';

import { DashboardReport } from '@components/subscription-plan/dashboard-report';
import { ModulesBanner } from '@components/subscription-plan/modules-banner';
import { IMarketplace } from '@interfaces/marketplace';
import { NRegistration } from '@interfaces/registration';
import { FC } from 'react';

export const DefaultDashboard: FC<{
    userPlan?: NRegistration.TUsedPlan;
    applications: IMarketplace.IApplication[];
    registration?: NRegistration.IRegistrationForDashboard;
    onClickUpgradeTable: () => void;
    onChangeOpenModal: (value: boolean, module?: IMarketplace.IApplication) => void;
}> = (props) => {
    const { userPlan, applications, registration, onChangeOpenModal, onClickUpgradeTable } = props;

    return (
        <>
            <section className="default-dashboard">
                <DashboardReport
                    userPlan={userPlan}
                    applications={applications}
                    registration={registration}
                    onChangeOpenModal={onChangeOpenModal}
                    onClickUpgradeTable={onClickUpgradeTable}
                />

                {applications?.length ? (
                    <ModulesBanner
                        onChangeOpenModal={onChangeOpenModal}
                        applications={applications}
                    />
                ) : null}
            </section>
        </>
    );
};
