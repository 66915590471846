import './styles.scss';

import { AppLogo } from '@enums/app-logos.enum';
import { IResourceComponentsProps, useGetIdentity } from '@refinedev/core';
import { Spin } from 'antd';
import { getEnvConfig } from 'getEnvConfig';
import { isBoolean } from 'lodash';
import { IntroduceIndex } from 'pages/dashboard/introduce';
import { useEffect, useState } from 'react';

import { SA_ROLE } from '../../constants/index.constant';
import { IUser } from '../../interfaces/user';
import { FFoodDashboard } from '../ffood-dashboard';
import { SaRevenueIndex } from '../sa-revenue';

interface IDashboardOptions {
    name: string;
}

type Props = {
    onlyAdmin?: boolean;
};

export const DashboardIndex: React.FC<
    IResourceComponentsProps<unknown, IDashboardOptions> & Props
> = ({ onlyAdmin = false }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { data: useIdentity } = useGetIdentity<IUser.IUserDto>();

    const isTenantOwner = useIdentity?.isTenantOwner;
    const isFFood = getEnvConfig.MAIN_LOGO === AppLogo.FFOOD;
    const isSystemAdmin = useIdentity?.roles && useIdentity?.roles.indexOf(SA_ROLE) !== -1;

    useEffect(() => {
        if (!isBoolean(isTenantOwner)) {
            return;
        }

        if (onlyAdmin && !isTenantOwner) {
            window.location.href = '/403';
        }

        setIsLoading(false);
    }, [isTenantOwner]);

    if (isLoading) {
        return <Spin spinning className="h-screen w-screen flex justify-center items-center" />;
    }

    return (
        <>
            {isSystemAdmin ? (
                <SaRevenueIndex />
            ) : (
                <>
                    <section className="header-container mb-6">
                        {isFFood ? <FFoodDashboard /> : <IntroduceIndex />}
                    </section>
                </>
            )}
        </>
    );
};
