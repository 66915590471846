import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles.scss';

import { PERMISSIONS } from '@constants/permission';
import { ModuleTypeEnum } from '@enums/index';
import { usePermissions } from '@refinedev/core';
import { validPermission } from '@utils/resource';
import { Button, Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { APPLICATIONS } from '../../constants/index.constant';
import { IMarketplace } from '../../interfaces/marketplace';

const getApplicationsContent = (
    id: string,
): { title: string; description: string; buttonTitle: string; activeModule: boolean } => {
    const { data: permissions } = usePermissions<string[]>();

    let title = '',
        activeModule = true;

    switch (id) {
        case APPLICATIONS.PAYGATE.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.PAYGATE_LIST);
            title = ModuleTypeEnum.PAYGATE;
            break;
        }

        case APPLICATIONS.TAX.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.TAX_LIST);
            title = ModuleTypeEnum.TAX;
            break;
        }

        case APPLICATIONS.TICKET.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.TICKET_LIST);
            title = ModuleTypeEnum.TICKET;
            break;
        }

        case APPLICATIONS.CLOUD.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.CLOUD_LIST);
            title = ModuleTypeEnum.CLOUD;
            break;
        }

        case APPLICATIONS.FNET_MOBILE.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.FNET_MOBILE_LIST);
            title = ModuleTypeEnum.FNET_MOBILE;
            break;
        }

        case APPLICATIONS.COMBO_VIP.id: {
            activeModule = validPermission(permissions || [], PERMISSIONS.COMBO_VIP_LIST);
            title = ModuleTypeEnum.COMBO_VIP;
            break;
        }
    }
    let description = `${title}.unused_case.description`,
        buttonTitle = 'try_now';

    if (activeModule) {
        buttonTitle = 'add_transaction';
        if (
            [
                APPLICATIONS.CLOUD.id,
                APPLICATIONS.FNET_MOBILE.id,
                APPLICATIONS.COMBO_VIP.id,
            ].includes(id)
        ) {
            buttonTitle = 'extend_more';
        }
        description = `${title}.used_case.description`;
    }

    return { title, description, buttonTitle, activeModule };
};

const Item: FC<{
    application: IMarketplace.IApplication;
    onChangeOpenModal: (value: boolean, application: IMarketplace.IApplication) => void;
}> = ({ application, onChangeOpenModal }) => {
    const { t } = useTranslation('common');
    const { title, description, buttonTitle, activeModule } = getApplicationsContent(
        application.id,
    );

    return (
        <div className="item-detail">
            {/* Decorative Circles */}
            <div className="absolute top-[-40px] left-[-95px] w-56 h-56 bg-[#F5852433] rounded-full z-0"></div>
            <div className="absolute top-[-60px] right-[-40px] w-32 h-32 bg-[#F5852433] rounded-full z-0"></div>
            <div className="information">
                <h3 className="title">{t(`modules_banner.${title}.title`)}</h3>
                <p className="description">{t(`modules_banner.${description}`)}</p>
                <Button
                    type="primary"
                    className="button-action"
                    onClick={() => {
                        onChangeOpenModal(true, application);
                    }}
                    icon={
                        activeModule ? <img src="/images/icons/star.svg" className="w-5" /> : null
                    }
                >
                    <p>{t(`modules_banner.actions.${buttonTitle}`)}</p>
                </Button>
            </div>
        </div>
    );
};

export const ModulesBanner: FC<{
    applications: IMarketplace.IApplication[];
    onChangeOpenModal: (value: boolean) => void;
}> = ({ onChangeOpenModal, applications }) => {
    return (
        <>
            <section className="my-5 modules-banner">
                <div className="slider-container">
                    <Row gutter={[16, 16]}>
                        {applications?.map((application) => (
                            <Col xl={8} md={12} sm={24} className="w-full" key={application.id}>
                                <div
                                    className="flex-1 item relative overflow-hidden rounded-lg"
                                    key={application.id}
                                >
                                    <Item
                                        application={application}
                                        onChangeOpenModal={onChangeOpenModal}
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </section>
        </>
    );
};
