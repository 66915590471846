import './styles.scss';

import { useApplicationPrices } from '@components/subscription-plan/hooks';
import { IMarketplace } from '@interfaces/marketplace';
import { Button, Form, Input, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyType } from '../../enums/currency-type.enum';
import { NRegistration } from '../../interfaces/registration';
import { formatPriceBaseOnCurrency } from '../../utils/resource';
import { CustomModal } from '../modules/modal-custom';
import { SubscriptionForm } from '.';

type IDrawerRegistrationForm = {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    handlePayment: (values: SubscriptionForm, totalPayment: number) => void;
    registration?: NRegistration.IRegistrationForDashboard;
    application: IMarketplace.IApplication;
};

export const SubscribeModuleModal: React.FC<IDrawerRegistrationForm> = ({
    isOpen,
    isLoading,
    registration,
    onClose,
    handlePayment,
    application,
}) => {
    const { t } = useTranslation(['common']);
    const [form] = Form.useForm();
    const [isRenderTotal, setIsRenderTotal] = useState<boolean>(false);
    const prices = useApplicationPrices(application.id);

    const renderTotalPayment = useMemo(() => {
        const values = form.getFieldsValue();

        const applicationPrice = prices.find(
            (price) => price.applicationPlanId === values.applicationPlanId,
        );
        if (!applicationPrice) return 0;

        setIsRenderTotal(false);

        return (
            applicationPrice.pricePerTier * applicationPrice.quantity +
            (applicationPrice.extraPrice || 0)
        );
    }, [isRenderTotal]);

    const renewOptions = application.plans.map((plan) => ({
        key: plan.applicationId,
        period: plan.period,
        value: plan.id,
    }));

    const onFinish = (value: SubscriptionForm) => {
        const plan = application.plans.find((plan) => plan.id === value.applicationPlanId);
        value.applicationId = application.id;
        value.quantity = plan?.period;
        value.id = registration?.id || undefined;

        handlePayment(value, renderTotalPayment);
    };

    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={!isLoading}
            onCancel={onClose}
            maskClosable={false}
            width={550}
        >
            <div>
                <p className="text-xl italic">
                    {t(`plan_form.actions.register`, { name: application.title })}
                </p>
            </div>
            <Form layout="vertical" className="upgrade-modal" form={form} onFinish={onFinish}>
                <Form.Item
                    label={t('plan_form.fields.renew_license')}
                    name={'applicationPlanId'}
                    rules={[
                        {
                            required: true,
                            message: t('plan_form.fields.renew_license_required'),
                        },
                    ]}
                >
                    <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showSearch={false}
                        placeholder={t('plan_form.fields.expired_time_placeholder')}
                        onChange={() => {
                            setIsRenderTotal(true);
                        }}
                    >
                        {renewOptions.map((option) => {
                            return (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.period} {t('subscription_order.frequency_type.month')}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    className="mt-2"
                    label={t('plan_form.fields.reference_code')}
                    name={'referenceCode'}
                    initialValue={registration?.referenceCode}
                >
                    <Input placeholder={t('plan_form.fields.reference_code_placeholder')} />
                </Form.Item>

                <p className="mb-2">
                    *{t('plan_form.fields.total_price')}:{' '}
                    {formatPriceBaseOnCurrency(renderTotalPayment, CurrencyType.Vnd)}
                </p>
                <Button
                    type="primary"
                    className="w-full flex justify-center"
                    htmlType="submit"
                    loading={isLoading}
                >
                    {t('plan_form.actions.payment_btn')}
                </Button>
            </Form>
        </CustomModal>
    );
};
