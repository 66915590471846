import { PlusOutlined } from '@ant-design/icons';
import { SERVICE_MODULE_OPTION_ID } from '@constants/index.constant';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { ItemTypeEnum } from '@enums/item-type.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IMarketplace } from '@interfaces/marketplace';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { useSelect } from '@refinedev/antd';
import { useCustom, useGetIdentity, useList } from '@refinedev/core';
import { UnitEnum } from '@utils/date';
import { convertPrice, formatPercentage, formatPrice, getPriceWithTax } from '@utils/resource';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { isEqual, keyBy } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { IContact } from '../../../interfaces/contact';
import { ITenant } from '../../../interfaces/tenant';

interface IProps {
    form: any;
    dataProviderName: DataProviderNameEnum;
    orderId: string | null;
    apiUrl: string;
    id?: string;
    contact?: IContact.IContact;
    tenantId?: string;
    onChangeTenantId?: (tenantId: string | undefined) => void;
}

export const ItemsDetail: FC<IProps> = (props) => {
    const { form, dataProviderName, apiUrl, id, contact, tenantId, onChangeTenantId } = props;

    const { t } = useTranslation(['quote', 'common']);
    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
    // const [isModuleOptions, setIsModuleOptions] = useState<boolean[]>();
    const [applications, setApplications] = useState<IMarketplace.IApplication[]>([]);
    const [items, setItems] = useState<any>([]);
    const [totalOrder, setTotalOrder] = useState<number>(0);

    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const isReseller = userIdentity?.userType === UserTypeEnum.RESELLER;
    const { data: saleEarning, refetch: getSaleEarnings } = useCustom({
        url: `${apiUrl}/v1/sales/sale-earning`,
        method: 'post',
        queryOptions: {
            enabled: false,
        },
        config: {
            payload: {
                items: items.map((item: any) => {
                    return {
                        ...item,
                        type:
                            item.serviceId === SERVICE_MODULE_OPTION_ID
                                ? ItemTypeEnum.MODULE
                                : ItemTypeEnum.PRODUCT,
                    };
                }),
            },
        },
    });
    const { data: listApplications, refetch: refetchApplication } =
        useList<IMarketplace.IApplication>({
            dataProviderName,
            resource: `v1/application`,
            config: {
                hasPagination: false,
                filters: [
                    {
                        field: 'includeUnpublish',
                        operator: 'eq',
                        value: false,
                    },
                ],
            },
            queryOptions: {
                enabled: false,
            },
        });

    const { data: tenantList, refetch: refetchTenantList } = useCustom<
        ITenant.IGetTenantsByEmail[]
    >({
        url: `${apiUrl}/v1/tenant/get-by-email`,
        method: 'post',
        config: {
            payload: {
                email: contact?.email,
            },
        },
        queryOptions: {
            enabled: false,
        },
    });

    const applicationsTenantMap = useMemo(() => {
        return tenantList?.data?.reduce(
            (acc, tenant) => {
                tenant.applicationIds?.forEach((appId) => {
                    if (!acc[appId]) {
                        acc[appId] = new Set<string>();
                    }
                    acc[appId].add(tenant.id);
                });
                return acc;
            },
            {} as Record<string, Set<string>>,
        );
    }, [tenantList]);
    useEffect(() => {
        if (form.getFieldValue('items') && id) {
            form.getFieldValue('items').forEach((item: any) => {
                if (item.targetId) {
                    onChangeTenantId?.(item.targetId);
                }
            });
        }
    }, [form.getFieldValue('items')]);
    useEffect(() => {
        const fetchApplications = async () => {
            await refetchApplication();
        };

        fetchApplications();
    }, []);

    useEffect(() => {
        const fetchTenants = async () => {
            if (contact) {
                await refetchTenantList();
            }
        };

        fetchTenants();
    }, [contact]);

    useEffect(() => {
        if (listApplications?.data) {
            const processedApplications = listApplications.data;
            processedApplications.forEach((app) => {
                app.plans.forEach((plan) => {
                    plan.pricing = plan.pricing / 100;
                });
            });
            if (!isEqual(processedApplications, applications)) {
                setApplications(processedApplications);
            }
        }
    }, [listApplications]);
    useEffect(() => {
        onChangeItems();
        onCalculateTotalPrice();
    }, [form.getFieldValue('items')]);

    useEffect(() => {
        if (items.some((item: any) => !item.productId || !item.qty)) return;
        isReseller && getSaleEarnings();
    }, [items]);

    const { selectProps: serviceSelectProps, queryResult } = useSelect<IService>({
        dataProviderName,
        resource: 'v1/services/active-services',
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            mode: 'server',
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_services', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_services_failed', { ns: 'common' }),
                };
            }
        },
    });

    const services: any = keyBy(queryResult.data?.data, 'id');
    const moduleOption = {
        value: SERVICE_MODULE_OPTION_ID,
        label: t('services.module', { ns: 'common' }),
    };

    if (!services[SERVICE_MODULE_OPTION_ID] && applications) {
        services[SERVICE_MODULE_OPTION_ID] = {
            id: SERVICE_MODULE_OPTION_ID,
            name: t('Module'),
            products: applications.map((app) => {
                return {
                    id: app.id,
                    name: app.title,
                    price: app.plans[0].pricing,
                    frequency: app.plans[0].frequency,
                    margin: app.margin,
                    taxPercentage: app?.taxPercentage || 0,
                    unitCost: app?.unitCost || 0,
                };
            }),
        };
    }

    const getFrequencyTitle = (frequency: string) => {
        switch (frequency) {
            case UnitEnum.MONTH:
            case ProductFrequencyEnum.PER_MONTH.toString():
                return t(`services.frequency.${ProductFrequencyEnum.PER_MONTH}`, { ns: 'common' });
            default:
                return t(`services.frequency.${ProductFrequencyEnum.ONE_TIME_PAYMENT}`, {
                    ns: 'common',
                });
        }
    };

    const onChangeProduct = (productId: string, index: number) => {
        const items = form.getFieldValue('items');
        const updateItem = (item: any, product: any) => {
            item.productId = productId;
            item.productName = product.name || product.title;
            item.unitPrice = product.price || product.plans?.[0]?.pricing;
            item.qty = 1;
            item.frequency = product.frequency || product.plans?.[0]?.frequency;
            item.margin = product.margin;
            item.unitCost = product.unitCost;
            item.taxPercentage = product.taxPercentage;
            item.targetId = null;
        };

        const products = services[items[index].serviceId].products;
        const selectedProduct = products.find((p: any) => p.id === productId);

        if (tenantId && applicationsTenantMap?.[productId]?.has(tenantId)) {
            onChangeTenantId?.(undefined);
        }
        updateItem(items[index], selectedProduct);

        form.setFieldsValue({ items: items });
        onChangeItems();
        onCalculateTotalPrice();
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeItems = () => {
        const items = form.getFieldValue('items');

        const itemCalculateSaleEarning = items.map((item: any) => {
            return {
                productId: item.productId,
                qty: item.qty,
                serviceId: item.serviceId,
                margin: item.margin,
            };
        });

        setItems(itemCalculateSaleEarning);
    };

    const onCalculateTotalPrice = () => {
        const items = form?.getFieldValue('items');
        let totalPrice = 0;
        if (items) {
            let total = 0;
            for (const item of items) {
                total += getPriceWithTax(item?.qty * item?.unitPrice || 0, item.taxPercentage);
            }

            totalPrice = total;
        }
        setTotalOrder(totalPrice);
    };

    const onChangeService = (serviceId: string, index: number) => {
        const items = form.getFieldValue('items');
        items[index].productId = undefined;
        items[index].productName = null;

        items[index].unitPrice = null;
        items[index].unitCost = null;
        items[index].qty = items[index].qty || 1;
        items[index].frequency = null;
        items[index].margin = 0;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeQty = () => {
        setServiceUpdated(!serviceUpdated);
        onChangeMargin();
        onChangeItems();
    };

    const onChangeMargin = () => {
        onCalculateTotalPrice();
        setServiceUpdated(!serviceUpdated);
    };

    const genTenantList = (applicationId: string): ITenant.IGetTenantsByEmail[] | undefined => {
        if (!items || !tenantList?.data) return;

        const invalidTenants = new Set<string>();
        for (const item of items) {
            if (item.serviceId === SERVICE_MODULE_OPTION_ID && item.productId) {
                const tenantIds = applicationsTenantMap?.[item.productId];
                if (tenantIds) {
                    tenantIds.forEach((tenantId) => {
                        invalidTenants.add(tenantId);
                    });
                }
            }
        }

        return tenantList.data.filter((tenant) => {
            const appIdsSet = new Set(tenant.applicationIds);
            return !appIdsSet.has(applicationId) && !invalidTenants.has(tenant.id);
        });
    };
    const handleSelectOneTenant = (tenantId: string) => {
        onChangeTenantId?.(tenantId);
    };
    useEffect(() => {
        if (tenantId) {
            const items = form.getFieldValue('items');
            items.forEach((item: any, idx: number) => {
                if (item.serviceId === SERVICE_MODULE_OPTION_ID) {
                    items[idx].targetId = tenantId;
                }
            });
            form.setFieldsValue({ items });
        }
    }, [tenantId]);
    return (
        <div>
            <div className="section-header">{t('quotes.heading.itemDetails')}</div>
            <Row className="item-list">
                <Col span={24}>
                    <Row
                        className={` ${
                            window.screen.width < 578 ? 'table-header-none' : ''
                        } table-header`}
                    >
                        <Col span={4}>{t('quotes.fields.item.label')}</Col>
                        <Col span={4} className="label-field-required">
                            {t('quotes.fields.unitCost.label')}
                        </Col>
                        <Col span={3}>{t('quotes.fields.unitPrice.label')}</Col>
                        <Col span={3}>{t('quotes.fields.qty.label')}</Col>
                        <Col span={3}>{t('quotes.fields.frequency.label')}</Col>
                        <Col span={3} className="label-field-required">
                            {t('quotes.fields.taxAmount.label')}
                        </Col>
                        <Col span={4}>{t('quotes.fields.total.label')}</Col>
                    </Row>
                    <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index: number) => {
                                    const formItem = form.getFieldValue('items')[index];
                                    const total = formItem?.unitPrice * formItem?.qty || 0;
                                    // Check if the item is an existing one by looking for the id

                                    const canDelete = form.getFieldValue('items')?.length > 1;

                                    if (!formItem?.id) {
                                        form.setFieldsValue({
                                            items: form
                                                .getFieldValue('items')
                                                .map((item: any, i: number) =>
                                                    i === index
                                                        ? { ...item, id: item?.id || uuidv4() }
                                                        : item,
                                                ),
                                        });
                                    }

                                    const options = serviceSelectProps?.options || [];

                                    if (
                                        applications &&
                                        !options.find((o) => o.value === SERVICE_MODULE_OPTION_ID)
                                    ) {
                                        options.push(moduleOption);
                                    }

                                    if (formItem && !formItem.serviceId && formItem.productId) {
                                        formItem.serviceId = SERVICE_MODULE_OPTION_ID;

                                        const items = form.getFieldValue('items');
                                        items[index] = formItem;
                                        setItems([...items]);
                                    }

                                    const isSelectedModuleService =
                                        formItem?.serviceId === SERVICE_MODULE_OPTION_ID ||
                                        formItem?.serviceId === null;

                                    let curTenantList = isSelectedModuleService
                                        ? genTenantList(formItem?.productId)
                                        : [];

                                    const isInvoiceItemExisted =
                                        !!id && !!formItem.id && !formItem.isNew;

                                    if (isInvoiceItemExisted) {
                                        curTenantList = tenantList?.data;
                                    }
                                    if (isSelectedModuleService && !id) {
                                        formItem.targetId = tenantId;
                                    }
                                    if (isSelectedModuleService && id && !formItem.targetId) {
                                        formItem.targetId = tenantId;
                                    }
                                    return (
                                        <Row className="list-product-item item-custom" key={key}>
                                            <Col xs={24} sm={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'serviceId']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.item.required',
                                                            ),
                                                        },
                                                    ]}
                                                    label={t('quotes.fields.item.label')}
                                                >
                                                    <Select
                                                        getPopupContainer={(trigger) =>
                                                            trigger.parentNode
                                                        }
                                                        options={serviceSelectProps.options}
                                                        showSearch={false}
                                                        onChange={(item: any) =>
                                                            onChangeService(item, index)
                                                        }
                                                        placeholder={'Select service'}
                                                        disabled={isInvoiceItemExisted}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'productId']}
                                                    label={t('quotes.fields.product.label')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.product.required',
                                                            ),
                                                        },
                                                    ]}
                                                    className="product-custom"
                                                >
                                                    <Select
                                                        getPopupContainer={(trigger) =>
                                                            trigger.parentNode
                                                        }
                                                        showSearch={false}
                                                        placeholder={t(
                                                            'quotes.fields.product.placeholder',
                                                        )}
                                                        onChange={(item) =>
                                                            onChangeProduct(item, index)
                                                        }
                                                        disabled={isInvoiceItemExisted}
                                                    >
                                                        {services[
                                                            formItem?.serviceId
                                                        ]?.products?.map((product: any) => {
                                                            let hidden = false;
                                                            const items =
                                                                form.getFieldValue('items');

                                                            if (isSelectedModuleService) {
                                                                const existedItem = items.find(
                                                                    (item: any) =>
                                                                        item.productId ===
                                                                        product.id,
                                                                );

                                                                if (existedItem) {
                                                                    hidden = true;
                                                                }
                                                            }

                                                            if (
                                                                tenantId &&
                                                                applicationsTenantMap?.[
                                                                    product.id
                                                                ]?.has(tenantId)
                                                            ) {
                                                                hidden = true;
                                                            }
                                                            return (
                                                                <Select.Option
                                                                    key={product.id}
                                                                    value={product.id}
                                                                    disabled={hidden}
                                                                >
                                                                    {product.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={4}>
                                                <div className="price-field cursor-pointer">
                                                    <Form.Item
                                                        label={t('quotes.fields.unitCost.label')}
                                                    >
                                                        {isSelectedModuleService ? (
                                                            <div className="ml-4">--</div>
                                                        ) : (
                                                            formatPrice(formItem?.unitCost * 1)
                                                        )}
                                                    </Form.Item>
                                                </div>{' '}
                                                {isSelectedModuleService &&
                                                    (!id ||
                                                        formItem?.targetId ||
                                                        formItem.isNew) && (
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'targetId']}
                                                            label={t('quotes.fields.tenant.label')}
                                                            className="product-custom"
                                                        >
                                                            <Select
                                                                allowClear
                                                                getPopupContainer={(trigger) =>
                                                                    trigger.parentNode
                                                                }
                                                                showSearch={false}
                                                                placeholder={t(
                                                                    'quotes.fields.tenant.placeholder',
                                                                )}
                                                                disabled={!!id}
                                                                onChange={(value) =>
                                                                    handleSelectOneTenant(value)
                                                                }
                                                            >
                                                                {curTenantList?.map(
                                                                    (
                                                                        tenant: ITenant.IGetTenantsByEmail,
                                                                    ) => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={tenant.id}
                                                                                value={tenant.id}
                                                                            >
                                                                                {tenant.name}
                                                                            </Select.Option>
                                                                        );
                                                                    },
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                            </Col>
                                            <Col xs={24} sm={3}>
                                                <div className="price-field cursor-pointer">
                                                    <Form.Item
                                                        label={t('quotes.fields.unitPrice.label')}
                                                    >
                                                        {formatPrice(formItem?.unitPrice * 1)}
                                                        {isReseller && formItem.margin ? (
                                                            <span className="charge">
                                                                <b>
                                                                    {t(
                                                                        'quotes.fields.margin.label',
                                                                    )}{' '}
                                                                    :{' '}
                                                                </b>
                                                                {`${formItem.margin}%`}
                                                            </span>
                                                        ) : null}
                                                    </Form.Item>
                                                </div>{' '}
                                            </Col>

                                            <Col xs={24} sm={3}>
                                                <Form.Item
                                                    {...restField}
                                                    label={t('quotes.fields.qty.label')}
                                                    name={[name, 'qty']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'quotes.fields.qty.required',
                                                            ),
                                                        },
                                                        {
                                                            min: 1,
                                                            type: 'number',
                                                            message:
                                                                'Quantity cannot be less than 1',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        defaultValue={1}
                                                        step={1}
                                                        precision={0}
                                                        onChange={onChangeQty}
                                                        disabled={
                                                            items?.[index]?.serviceId ===
                                                            SERVICE_MODULE_OPTION_ID
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={3}>
                                                <div className="price-field cursor-pointer">
                                                    <Form.Item
                                                        label={t('quotes.fields.frequency.label')}
                                                    >
                                                        {getFrequencyTitle(
                                                            formItem?.frequency?.toString(),
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={3}>
                                                <div className="price-field cursor-pointer">
                                                    <Form.Item
                                                        label={t('quotes.fields.taxAmount.label')}
                                                    >
                                                        {isSelectedModuleService ? (
                                                            <div className="ml-4">--</div>
                                                        ) : (
                                                            formatPercentage(
                                                                formItem?.taxPercentage,
                                                            )
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={4} className="total-wrapper ">
                                                <span className="total-price">
                                                    {t('quotes.fields.total.label')}
                                                </span>
                                                <div className="sub-total">
                                                    <div className="total-container">
                                                        <span>{formatPrice(total)}</span>
                                                        {canDelete && (
                                                            <img
                                                                className="ml-auto"
                                                                src="/images/icons/remove.svg"
                                                                onClick={() => {
                                                                    remove(name);
                                                                    onChangeQty();
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                                <div className="btn-add-container">
                                    <Button
                                        onClick={() => add({ qty: 1, isNew: true })}
                                        type="link"
                                        icon={<PlusOutlined />}
                                    >
                                        {t('quotes.actions.addItem')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <div className="sum-total flex justify-end">
                <span>{t('quotes.heading.total')}:</span>
                <span className="ml-2">{formatPrice(totalOrder)}</span>
            </div>
            {isReseller && (
                <div className="sum-total flex">
                    <span> {t('crm_internal.quote.total_commission', { ns: 'common' })}:</span>
                    <span className="ml-2">{formatPrice(saleEarning?.data?.data || 0)}</span>
                </div>
            )}
        </div>
    );
};
