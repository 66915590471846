import { MenuEnum } from 'components/layout/sidebar';
import { ResourceEnum } from 'enums/module-resources.enum';

export const BASE_ROUTE = '';
export const PAGE_CURRENT = 1;
export const PAGE_SIZE = 10;
export const SA_ROLE = '36a34a0d-c41c-4663-b1ec-674f38c52193';
export const DATETIME_LOCALE = 'us';
export const DOCUMENT_TYPE = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const DEFAULT_PRIMARY = '#6b6cff';
export const DEFAULT_SECONDARY = '#f4f4ff';
export const COLORS_CHART = ['#FFCF5C', '#0084F4', '#FFA26B', '#00C48C'];

export const TENANT_BUSINESS_SETTING_RESOURCE = 'v1/settings/brand-setting';

export const WHITE_LABEL_SETTING_RESOURCE = 'v1/wl-settings';
export const FAV_URL_KEY = 'fav_url';

export const MARKETPLACE_SETTING_RESOURCE = 'v1/bo/setting';

// TODO: define new menu item with order follow by it's order on sidebar
export const MODULE_ITEMS_ORDER: {
    [key in MenuEnum]?: { [key in ResourceEnum]?: ResourceEnum };
} = {
    dashboard: {},
    wl: {
        wl_revenue: ResourceEnum.WLRevenue,
        wl_service: ResourceEnum.WLService,
        wl_partner: ResourceEnum.WLPartner,
        wl_setting: ResourceEnum.WLSetting,
        wl_payouts: ResourceEnum.WLPayouts,
        wl_contact: ResourceEnum.WLContact,
        wl_quote: ResourceEnum.WLQuote,
        wl_invoice: ResourceEnum.WLInvoice,
        wl_order: ResourceEnum.WLOrder,
        wl_recurringInvoice: ResourceEnum.WLRecurringInvoice,
        wl_resource: ResourceEnum.WLResource,
        wl_helpdesk: ResourceEnum.WLHelpdesk,
    },
    pos: {
        pos_report: ResourceEnum.POSReport,
        pos_shop: ResourceEnum.POSShop,
        pos_table: ResourceEnum.POSTable,
        pos_tableGroup: ResourceEnum.POSTableGroup,
        pos_employee: ResourceEnum.POSEmployee,
        pos_extraFee: ResourceEnum.POSExtraFee,
        pos_payouts: ResourceEnum.POSPayouts,
        pos_setting: ResourceEnum.POSSetting,
        pos_generalSetting: ResourceEnum.POSGeneralSetting,
        pos_paymentSetting: ResourceEnum.POSPaymentSetting,
        pos_equipmentSetting: ResourceEnum.POSEquipmentSetting,
        pos_printSetting: ResourceEnum.POSPrintSetting,
        pos_paymentTerminal: ResourceEnum.POSPaymentTerminalSetting,
        pos_item: ResourceEnum.POSItem,
        pos_category: ResourceEnum.POSCategory,
        pos_option: ResourceEnum.POSOption,
    },
    crm: {},
};

export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_PERIOD = 'MM-DD-YYYY hh:mm a';
export const DATE_FORMAT_MINUTE = 'MM-DD-YYYY HH:mm';
export const DATE_FORMAT_TIME = 'MM-DD-YYYY HH:mm:ss';

export const VN_DATE_FORMAT = 'DD-MM-YYYY';
export const VN_DATE_FORMAT_PERIOD = 'DD-MM-YYYY hh:mm a';
export const VN_DATE_FORMAT_MINUTE = 'DD-MM-YYYY HH:mm';
export const VN_DATE_FORMAT_TIME = 'DD-MM-YYYY HH:mm:ss';

export const HOUR_FORMAT = 'HH:mm';

export const OTP_RESOURCE_NAME = {
    QUOTE: 'quote',
    INVOICE: 'invoice',
    PAYOUT: 'payout',
};

export const SERVICE_OTHER_OPTION_ID = '965ac398-6fdc-4112-beb7-8e5cdebba34c';

export const SERVICE_MODULE_OPTION_ID = 'bff5b137-6410-4368-8e1d-ae9b5e8362fd';

export const PIN_LENGTH = 6;

export const TABLET_SIZE = 990;

export const APPLICATIONS = {
    POS: {
        id: '42ec3ebe-54ed-4c11-b911-fab78c1f81c2',
        code: 'POS',
    },
    TAX: {
        id: '09baa059-f527-4f65-8cc0-86c6743ce4bf',
        code: 'TAX',
    },
    PAYGATE: {
        id: '0ff8546c-a4a1-4cbe-a4f2-88a58df776fd',
        code: 'PAYGATE',
    },
    CLOUD: {
        id: '22fe9e87-f371-44e2-8465-b3835b02b6a7',
        code: 'CLOUD',
    },
    FNET_MOBILE: {
        id: '4ae7e3d6-2f38-4b94-ac00-f4dd33afbcc2',
        code: 'FNET_MOBILE',
    },
    COMBO_VIP: {
        id: '04817551-c394-4618-b66b-59f4225cac3a',
        code: 'COMBO_VIP',
    },
    TICKET: {
        id: '2c42ce5a-a155-4c6f-b7e1-78e8a1c42448',
        code: 'TICKET',
    },
};
export const MESSAGE_SALES_PLAN_FREE_SUCCESS = 'sales_plan_free_success';

export const MESSAGE_SALES_TRIAL_PLAN_SUCCESS = 'sales_trial_plan_success';
export const MESSAGE_SALES_PLAN_COLLABORATOR_SUCCESS = 'sales_plan_collaborator_success';
export const MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE = 'contact_collaborator_to_upgrade';
export const MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON = 'contact_collaborator_to_add_on';
export const MESSAGE_CONTACT_COLLABORATOR_TO_RENEW = 'contact_collaborator_to_renew';
export const MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE_APPLICATION =
    'contact_collaborator_to_upgrade_application';

export const ROOT_TENANT = '5ce4f7ef-8644-4e78-91df-9c4412e3028a';
export const MAX_QTY_TABLE_FREE = 30;
export const MIN_QTY_TABLE_PAID_SERVICE = 10;

export const TIMEZONE_DEFAULT = 'Asia/Ho_Chi_Minh';
