import './styles.scss';

import { CustomModal } from '@components/modules/modal-custom';
import { useApplicationPrices } from '@components/subscription-plan/hooks';
import { PERMISSIONS } from '@constants/permission';
import { ModuleTypeEnum } from '@enums/index';
import { SubscriptionOrderTypeEnum } from '@enums/subscription-order-type.enum';
import { NSubscriptionPlan } from '@interfaces/subcription-plan';
import {
    HttpError,
    useApiUrl,
    useCustom,
    useCustomMutation,
    usePermissions,
} from '@refinedev/core';
import { validPermission } from '@utils/resource';
import { notification } from 'antd';
import { isBoolean } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    APPLICATIONS,
    MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE_APPLICATION,
} from '../../constants/index.constant';
import Response from '../../data-access/responses/response';
import { IMarketplace } from '../../interfaces/marketplace';
import { PriceCalculatorForm } from './forms/price-calculator-form';
import { SubPlanForm } from './forms/sub-plan-form';

type UpdateTenantPlan = {
    id?: string;
    applicationId: string;
    quantity?: number;
    referenceCode?: string;
};

const MAX_QUANTITY = 10000;

const getModalCase = (
    applicationId: string,
): { activeCase: boolean; moduleType: ModuleTypeEnum } => {
    const { data: permissions } = usePermissions<string[]>();
    let activeCase = false;
    let moduleType = ModuleTypeEnum.PAYGATE;

    switch (applicationId) {
        case APPLICATIONS.PAYGATE.id: {
            if (validPermission(permissions || [], PERMISSIONS.PAYGATE_LIST)) {
                activeCase = true;
            }
            break;
        }
        case APPLICATIONS.TICKET.id: {
            moduleType = ModuleTypeEnum.TICKET;

            if (validPermission(permissions || [], PERMISSIONS.TICKET_LIST)) {
                activeCase = true;
            }
            break;
        }
        case APPLICATIONS.TAX.id: {
            moduleType = ModuleTypeEnum.TAX;

            if (validPermission(permissions || [], PERMISSIONS.TAX_LIST)) {
                activeCase = true;
            }
            break;
        }
        case APPLICATIONS.CLOUD.id: {
            moduleType = ModuleTypeEnum.CLOUD;

            if (validPermission(permissions || [], PERMISSIONS.CLOUD_LIST)) {
                activeCase = true;
            }
            break;
        }
        case APPLICATIONS.FNET_MOBILE.id: {
            moduleType = ModuleTypeEnum.FNET_MOBILE;

            if (validPermission(permissions || [], PERMISSIONS.FNET_MOBILE_LIST)) {
                activeCase = true;
            }
            break;
        }
        case APPLICATIONS.COMBO_VIP.id: {
            moduleType = ModuleTypeEnum.COMBO_VIP;

            if (validPermission(permissions || [], PERMISSIONS.COMBO_VIP_LIST)) {
                activeCase = true;
            }
            break;
        }
    }
    return { activeCase, moduleType };
};

const PaymentForm: FC<{
    applicationId: string;
    quantity: number;
    onSubmit: () => void;
    onChangeQuantity: (value: number) => void;
    loading: boolean;
    onChangeReferralCode: (value: string) => void;
}> = (props) => {
    const apiUrl = useApiUrl();
    const tryModules = [APPLICATIONS.PAYGATE.id, APPLICATIONS.TICKET.id];

    const { t } = useTranslation('common');
    const { quantity, onSubmit, onChangeQuantity, applicationId, loading, onChangeReferralCode } =
        props;

    const { data: tryAppSub } = useCustom<Response<IMarketplace.IAppSubscription>, HttpError>({
        url: `${apiUrl}/v1/application-subscriptions/tenant/${applicationId}`,
        method: 'get',
        config: {},
        queryOptions: {
            enabled: tryModules.includes(applicationId),
        },
    });
    const { activeCase, moduleType } = getModalCase(applicationId);
    const appCase = activeCase ? 'used_case' : 'unused_case';
    const buttonTitle = !activeCase && tryModules.includes(applicationId) ? 'try_now' : 'payment';

    const [applicationPricesThresholds, setApplicationPricesThresholds] = useState<
        NSubscriptionPlan.PriceThreshold[]
    >([]);

    const [selectOptions, setSelectOptions] = useState<Array<{ label: string; value: number }>>([]);
    const applicationPrices = useApplicationPrices(applicationId);

    useEffect(() => {
        if (applicationPrices.length) {
            const prices: NSubscriptionPlan.PriceThreshold[] = applicationPrices.map((i) => ({
                threshold: i.quantity,
                price: +i.pricePerTier,
                step: i.step,
                minQuantity: i.minQuantity,
                discountPercentage: i.discountPercentage,
            }));

            if (applicationId === APPLICATIONS.TAX.id) {
                const requiredOptions = prices
                    .filter((i) => i.threshold <= MAX_QUANTITY)
                    .map((item) => ({
                        label: item.threshold?.toString(),
                        value: item.threshold,
                    }));

                setSelectOptions(requiredOptions);
            }

            setApplicationPricesThresholds(prices);
        }
    }, [applicationPrices]);

    const onChangeSelectOptions = (value: { label: string; value: number }) => {
        setSelectOptions((prev) => [...prev, value]);
    };

    const renderDescriptionList = () => (
        <div className="mt-6">
            <p className="font-semibold text-base mb-1">{t('modules_banner.title_received')}</p>
            <ul className="listed">
                <li className="my-1">
                    {t(`modules_banner.${moduleType}.${appCase}.modal.sub_description_1`)}
                </li>
                <li>{t(`modules_banner.${moduleType}.${appCase}.modal.sub_description_2`)}</li>
            </ul>
        </div>
    );

    // Check case already subscribed get free quantities but not logout -> disable button
    const hideButton = !activeCase && tryModules.includes(applicationId) && !!tryAppSub?.data?.data;

    return (
        <SubPlanForm
            loading={loading}
            title={t(`modules_banner.${moduleType}.${appCase}.modal.title`)}
            description={t(`modules_banner.${moduleType}.${appCase}.modal.description`)}
            descriptionList={renderDescriptionList()}
            submitText={t(`modules_banner.actions.${buttonTitle}`)}
            hideButton={hideButton}
            onSubmit={onSubmit}
            onChangeReferralCode={onChangeReferralCode}
            showReferralCode={!(tryModules.includes(applicationId) && !activeCase)}
            formSections={
                !activeCase && tryModules.includes(applicationId)
                    ? []
                    : [
                          {
                              title: t(`modules_banner.form.input_quantity_title`),
                              content: (
                                  <PriceCalculatorForm
                                      quantity={quantity}
                                      onChangeQuantity={onChangeQuantity}
                                      priceThresholds={applicationPricesThresholds || []}
                                      translations={{
                                          quantity: t(`modules_banner.form.transaction`),
                                          currency: t(`modules_banner.form.currency`),
                                          total: t(`modules_banner.form.total`),
                                      }}
                                      selectOptions={
                                          moduleType === ModuleTypeEnum.TAX
                                              ? selectOptions
                                              : undefined
                                      }
                                      onChangeSelectOptions={
                                          moduleType === ModuleTypeEnum.TAX
                                              ? onChangeSelectOptions
                                              : undefined
                                      }
                                  />
                              ),
                          },
                      ]
            }
        />
    );
};

export const ModuleItemModal: FC<{
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    registrationId?: string;
    item: IMarketplace.IApplication;
    handleOpenQrPayment: (data: {
        totalPayment: number;
        url: string;
        subscriptionOrderId: string;
        title: string;
    }) => void;
    typeModal?: SubscriptionOrderTypeEnum;
    setSubscriptionOrderId: (subscriptionOrderId: string) => void;
    setTitle: (title: string) => void;
    setSubTitle: (subTitle: string) => void;
}> = ({
    isOpen,
    onClose,
    item,
    registrationId,
    handleOpenQrPayment,
    typeModal,
    setSubscriptionOrderId,
    setTitle,
    setSubTitle,
}) => {
    const { t } = useTranslation('common');
    const [transactionCount, setTransactionCount] = useState<number>(0);
    const [referralCode, setReferralCode] = useState<string>();

    const apiUrl = useApiUrl();
    const { mutate: upgradeModule, isLoading: loadingUpgradeModule } = useCustomMutation<
        Response<{ qrUrl: string; subscriptionOrderId: string } | boolean>,
        HttpError,
        UpdateTenantPlan
    >();

    const onChangeReferralCode = (value: string) => {
        setReferralCode(value);
    };

    const moduleRegistration = () => {
        upgradeModule(
            {
                url: `${apiUrl}/v1/registration/subscription`,
                method: 'post',
                values: {
                    id: registrationId ?? undefined,
                    applicationId: item.id,
                    quantity: transactionCount,
                    referenceCode: referralCode,
                },
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error || 'unknown';
                    notification.error({
                        className: 'error-notification',
                        message: '',
                        description: t(`plan_form.errors.${code}`),
                    });
                    console.error(error);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    if (result) {
                        if (isBoolean(result.data)) {
                            notification.success({
                                message: t('modules_banner.register_success'),
                                description: t('notifications.refresh_token'),
                            });
                        }

                        const { qrUrl, subscriptionOrderId, paymentAmount } = result.data as {
                            qrUrl: string;
                            paymentAmount: number;
                            subscriptionOrderId: string;
                        };
                        switch (qrUrl) {
                            case MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE_APPLICATION: {
                                setTitle(t(`plan_form.actions.${typeModal}`));
                                setSubTitle(`notifications.${qrUrl}`);
                                setSubscriptionOrderId(subscriptionOrderId ?? '');
                                break;
                            }
                            default: {
                                handleOpenQrPayment({
                                    url: qrUrl,
                                    subscriptionOrderId,
                                    totalPayment: paymentAmount,
                                    title: t('module_banner.payment_success'),
                                });
                            }
                        }

                        onClose();
                    }
                },
            },
        );
    };

    const onChangeQuantity = (value: number) => {
        setTransactionCount(value);
    };

    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={!loadingUpgradeModule}
            onCancel={onClose}
            maskClosable={false}
            width={700}
            className="modules-modal"
        >
            <PaymentForm
                loading={loadingUpgradeModule}
                quantity={transactionCount}
                onChangeQuantity={onChangeQuantity}
                onSubmit={moduleRegistration}
                applicationId={item.id}
                onChangeReferralCode={onChangeReferralCode}
            />
        </CustomModal>
    );
};
